import React, { useEffect, useState } from "react"
import { Button, Modal } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import { AuthContainer, BackLink, FormWrapper, Divider, ButtonWrapper } from "./styles"
import { navigate } from "gatsby"
import { Signin } from "./signin"
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, deleteUser, OAuthProvider } from "firebase/auth"

const Authentication = () => {

  const auth = getAuth();

  const [showSignin, setShowSignin] = useState(false)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false)

  const [showCompleted, setShowCompleted] = useState(false)

  const urlParams = typeof window !== 'undefined' && new URLSearchParams(window.location.search);

  const googleSignin = () => {

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result, "google result")
        setOpenDeleteModal(true)
      }).catch((error) => {
        // console.log(error, "error")
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }

  const appleSignin = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result, "apple result")
        setOpenDeleteModal(true)
      }).catch((error) => {
        // console.log(error, "error")
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false)
    if (urlParams?.get("fromMobileApp")) {
      window.location.replace("https://aifithub.page.link/accountDeleteRedirectCancel");
    } else {
      window.open('', '_self', '');
      window.close();
    }
  }

  const goBack = async () => {
    const authUser = getAuth()
    await signOut(authUser)
      .then(() => {
      })
      .catch(error => {
      })
    setOpenDeleteModal(false);
    if (urlParams?.get("fromMobileApp")) {
      window.location.replace("https://aifithub.page.link/accountDeleteRedirectCancel");
    } else {
      window.open('', '_self', '');
      window.close();
    }
  }

  const deleteUserFn = async () => {
    const authUser = getAuth();
    const user = authUser.currentUser;
    await deleteUser(user).then(() => {
      setOpenDeleteModal(false);
      // setOpenDeleteSuccess(true)
      setShowCompleted(true)
    }).catch((error) => {
    });
  }

  const handleDeleteSuccess = () => {
    // setOpenDeleteSuccess(false)
    setShowCompleted(false)
    if (urlParams?.get("fromMobileApp")) {
      window.location.replace("https://aifithub.page.link/accountDeleteRedirect");
    } else {
      window.open('', '_self', '');
      window.close();
    }
  }

  const gotitFn = async () => {
    const authUser = getAuth()
    await signOut(authUser)
      .then(() => {
      })
      .catch(error => {
      })
    // setOpenDeleteSuccess(false);
    setShowCompleted(false)
    if (urlParams?.get("fromMobileApp")) {
      window.location.replace("https://aifithub.page.link/accountDeleteRedirect");
    } else {
      window.open('', '_self', '');
      window.close();
    }
  }

  return (
    <AuthContainer>
      {
        !showSignin && !showCompleted &&
        <div>
          <BackLink>
            <Button type="link" onClick={() => {
              if (urlParams?.get("fromMobileApp")) {
                window.location.replace("https://aifithub.page.link/accountDeleteRedirectCancel");
              } else {
                window.open('', '_self', '');
                window.close();
              }
            }}>
              <StaticImage src="../../images/arrowBack.webp" alt="back" placeholder="none" />
            </Button>
          </BackLink>
          <FormWrapper>
            <span className="logo">
              <StaticImage src="../../images/Logo.webp" alt="logo" placeholder="none" />
            </span>
            <h3>Authentication Required</h3>
            <Button
              type="secondary"
              icon={<StaticImage src="../../images/email.webp" alt="email" placeholder="none" />}
              className="emailBtn"
              onClick={() => setShowSignin(true)}
            >
              Sign in with email
            </Button>
            <Divider>
              <span>or continue with</span>
            </Divider>
            <ButtonWrapper>
              <Button
                type="secondary"
                icon={<StaticImage src="../../images/appleIcon.webp" alt="apple" placeholder="none" />}
                className="emailBtn"
                onClick={appleSignin}
                style={{ marginTop: "0px" }}
              >
                Apple
              </Button>
              <Button
                type="secondary"
                icon={<StaticImage src="../../images/googleIcon.webp" alt="google" placeholder="none" />}
                className="emailBtn"
                onClick={googleSignin}
                style={{ marginTop: "0px" }}
              >
                Google
              </Button>
            </ButtonWrapper>
          </FormWrapper>
        </div>
      }
      {
        showSignin && !showCompleted && <Signin onClick={() => setShowSignin(false)} onDelete={() => setOpenDeleteModal(true)} />
      }
      {
        showCompleted &&
        <div>
          <BackLink>
            <Button type="link" onClick={() => {
              if (urlParams?.get("fromMobileApp")) {
                window.location.replace("https://aifithub.page.link/accountDeleteRedirectCancel");
              } else {
                window.open('', '_self', '');
                window.close();
              }
            }}>
              <StaticImage src="../../images/arrowBack.webp" alt="back" placeholder="none" />
            </Button>
          </BackLink>
          <FormWrapper>
            <span className="deleteImg">
              <StaticImage src="../../images/deleteSuccess.webp" alt="deleted" placeholder="none" />
            </span>
            <h3 style={{ fontWeight: 700 }}>Account Deleted</h3>
            <p className="modalDesc">We are sorry to see you leave.<br></br> You are always welcome to join Fit Hub again</p>
            <Button type="secondary" className="outlinedBtn" onClick={gotitFn}>Got it</Button>
          </FormWrapper>
        </div>
      }
      <Modal title="" open={openDeleteModal} onCancel={handleDeleteCancel} footer="" className="deleteModal" centered maskClosable={false}>
        <div className="infoContainer">
          <h3 className="modalTitle">Permanently Delete Account</h3>
          <p className="modalDesc">This cannot be undone</p>
          <Button type="primary" className="filledBtn" onClick={deleteUserFn}>Delete Now</Button>
          <Button type="secondary" className="outlinedBtn" onClick={goBack}>Go Back</Button>
        </div>
      </Modal>
      <Modal title="" open={openDeleteSuccess} onCancel={handleDeleteSuccess} footer="" className="deleteModal" centered maskClosable={false}>
        <div className="infoContainer">
          <StaticImage src="../../images/deleteSuccess.webp" alt="deleted" placeholder="none" />
          <h3 className="modalTitle">Account Deleted</h3>
          <p className="modalDesc">We are sorry to see you leave. You are always welcome to join Fit Hub again</p>
          <Button type="secondary" className="outlinedBtn" onClick={gotitFn}>Got it</Button>
        </div>
      </Modal>
    </AuthContainer>
  )
}

export default Authentication

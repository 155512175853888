import styled from "styled-components";
import * as palette from "../../styles/variables";

export const AuthContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 0px 20px;
`;

export const BackLink = styled.div`
  padding: 30px 0px;
  .ant-btn {
    border: none !important;
    padding: 0px !important;
    &:hover, &:active, &:focus {
      border: none !important;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    img {
      width: 76px;
      height: 67px;
    }
    margin-bottom: 40px;
  }
  .deleteImg {
    img {
      width: 56px;
      height: 56px;
    }
    margin-bottom: 25px;
  }
  .modalDesc {
    text-align: center;
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #DFE3EA;
    margin: 12px 0px 16px;
  }
  h3 {
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: ${palette.WHITE_COLOR};
  }
  .emailBtn {
    border-radius: 48px;
    border: 1px solid #5C6B7E !important;
    background: linear-gradient(0deg, #25303E, #25303E), linear-gradient(0deg, #5C6B7E, #5C6B7E) !important;
    height: 48px;
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${palette.WHITE_COLOR};
    width: 100%;
    z-index: 2;
    margin-top: 40px;
    &:hover, &:active, &:focus {
      color: ${palette.WHITE_COLOR} !important;
    }
  }

  .confirmBtn {
    height: 40px;
    background: #3ADCCC !important;
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #003732 !important;
    width: 100%;
    z-index: 2;
    border-radius: 48px;
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover, &:active, &:focus {
      color: #003732 !important;
    }
  }
  .inputField {
    position: relative;
    .emailInput, .ant-input-password {
      width: 100%;
      z-index: 2;
      border-radius: 48px;
      height: 56px;
      border: 1px solid #5C6B7E;
      background: transparent !important;
      box-shadow: none !important;
      padding: 4px 16px;
      &:focus {
        border-color: #3ADCCC !important;
      }
    }
    .emailInput, .ant-input-password input {
      color: #8FA3BC !important;
      font-family: ${palette.CONTENT_FONT_FAMILY};
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
    .ant-input-password {
      &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover  {
        border-color: #3ADCCC !important;
      }
      .ant-input-password-icon.anticon {
        color: #8FA3BC;
      }
    }
    .placeholder {
      position: absolute;
      top: 50%;
      color: #8FA3BC;
      left: 12px;
      transform: translateY(-50%);      
      display: inline-block;
      background: rgb(10, 23, 29);
      padding: 0px 4px;
      transition: top 0.3s ease, left 0.4s ease;
      max-width: 0.01px;
      font-family: ${palette.CONTENT_FONT_FAMILY};
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      opacity: 0.7;
    }
  }

  .focusedElement {
    .placeholder {
      max-width: 100%;
      top: -8px;
      transform: none;
      z-index: 2;
      color: #3ADCCC;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      opacity: 1;
    }
  }

  .validInput {
    .placeholder {
      color: #8FA3BC;
      opacity: 1;
    }
  }
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin: 22px 0px;
  span {
    margin: 0px 20px;
    display: inline-block;
    font-family: ${palette.CONTENT_FONT_FAMILY};
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #D8DBD9;
  }
  &:before {
    content: "";
    display: block;
    width: 28%;
    height: 1px;
    background: #B1CCC7;
    left: 0;
    top: 50%;
    position: absolute;
  }
  &:after {
    content: "";
    display: block;
    width: 28%;
    height: 1px;
    background: #B1CCC7;
    right: 0;
    top: 50%;
    position: absolute;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  .ant-btn {
    flex: 1;
  }
`;

import { Button, Form, Input, Modal } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { BackLink, FormWrapper } from "./styles"
//firebase
import app from 'gatsby-plugin-firebase-v9.0'
import { signInWithEmailAndPassword, getAuth, signOut, deleteUser } from "firebase/auth"

export const Signin = ({ onClick, onDelete }) => {

  const auth = getAuth(app)

  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(false)

  const onFinish = async (values) => {
    setDisabled(true)
    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then(userCredential => {
        console.log(userCredential, "userCredential")
        setDisabled(false)
        form.resetFields();
        onDelete();
      })
      .catch(error => {
        setDisabled(false)
        if (error.code === "auth/wrong-password") {
          form.setFields([
            {
              name: "password",
              errors: ["Incorrect Password"],
            },
          ]);
        } else if (error.code === "auth/invalid-email") {
          form.setFields([
            {
              name: "email",
              errors: ["Invalid Email"],
            },
          ]);
        } else if (error.code === "auth/user-not-found") {
          form.setFields([
            {
              name: "email",
              errors: ["User not found"],
            },
          ]);
        } else if (error.code === "auth/too-many-requests") {
          form.setFields([
            {
              name: "password",
              errors: ["Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."],
            },
          ]);
        }
      })
  }

  const focusClick = param => {
    if (param === "email") {
      var element = document.getElementById("emailWrapper");
      element.classList.add("focusedElement");
      element.classList.remove("validInput");
    }

    if (param === "password") {
      var element = document.getElementById("passwordWrapper");
      element.classList.add("focusedElement");
      element.classList.remove("validInput");
    }
  }

  const blurClick = param => {
    if (param === "email" && !form.getFieldValue(param)) {
      var element = document.getElementById("emailWrapper");
      element.classList.remove("focusedElement");
    } else {
      var element = document.getElementById("emailWrapper");
      element.classList.add("validInput");
    }

    if (param === "password" && !form.getFieldValue(param)) {
      var element = document.getElementById("passwordWrapper");
      element.classList.remove("focusedElement");
    } else {
      var element = document.getElementById("passwordWrapper");
      element.classList.add("validInput");
    }
  }

  return (
    <div>
      <BackLink>
        <Button type="link" onClick={onClick}>
          <StaticImage src="../../images/arrowBack.webp" alt="back" placeholder="none" />
        </Button>
      </BackLink>
      <FormWrapper>
        <h3>Sign In</h3>
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          style={{ width: "100%", marginTop: "30px" }}
        >
          <Form.Item
            label=""
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email",
              },
            ]}
            style={{ marginBottom: "34px" }}
          >
            <div className="inputField" id="emailWrapper">
              <Input className="emailInput" onFocus={() => focusClick("email")} onBlur={() => blurClick("email")} />
              <span className="placeholder">Email</span>
            </div>
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
            style={{ marginBottom: "34px" }}
          >
            <div className="inputField" id="passwordWrapper">
              <Input.Password onFocus={() => focusClick("password")} onBlur={() => blurClick("password")} />
              <span className="placeholder">Password</span>
            </div>
          </Form.Item>
          <Form.Item className="buttonInput" style={{ marginTop: "50px" }}>
            <Button type="primary" htmlType="submit" disabled={disabled} className="confirmBtn">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </div>
  )
}

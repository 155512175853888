import React from "react"
import Authentication from "../components/Authentication"
import Seo from "../components/seo"

const DeletePage = () => {
  return (
    <Authentication />
  )
}

export default DeletePage

export const Head = () => (<Seo title="Fithub - Delete Account" />);